import { useDispatch } from 'react-redux';
import { setKey } from '../../store/reducers/main';
import './Keyboard.scss'

export default function Keyboard({ onSubmit }){
  const dispatch = useDispatch()

  const onClick = (key) => {
    if(key === 'Enter'){
      onSubmit()
    } else dispatch(setKey(key));
  }

  return(
    <div
    className="keyboard"
    role="group"
    aria-label="Keyboard"
  >
    <div className="keyboard-row">
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="q" className="keyboard-row-key">
        q
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="w" className="keyboard-row-key">
        w
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="e" className="keyboard-row-key">
        e
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="r" className="keyboard-row-key">
        r
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="t" className="keyboard-row-key">
        t
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="y" className="keyboard-row-key">
        y
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="u" className="keyboard-row-key">
        u
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="i" className="keyboard-row-key">
        i
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="o" className="keyboard-row-key">
        o
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="p" className="keyboard-row-key">
        p
      </button>
    </div>
    <div className="keyboard-row">
      <div data-testid="spacer" className="Key-module_half__HooWu"></div>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="a" className="keyboard-row-key">
        a
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="s" className="keyboard-row-key">
        s
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="d" className="keyboard-row-key">
        d
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="f" className="keyboard-row-key">
        f
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="g" className="keyboard-row-key">
        g
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="h" className="keyboard-row-key">
        h
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="j" className="keyboard-row-key">
        j
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="k" className="keyboard-row-key">
        k
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="l" className="keyboard-row-key">
        l
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="ñ" className="keyboard-row-key">
        ñ
      </button>
      <div data-testid="spacer" className="Key-module_half__HooWu"></div>
    </div>
    <div className="keyboard-row">
      <button
        onClick={(e) => onClick(e.target.dataset.key)}
        type="button"
        data-key="Enter"
        className="enter keyboard-row-key"
      >
        enter
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="z" className="keyboard-row-key">
        z
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="x" className="keyboard-row-key">
        x
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="c" className="keyboard-row-key">
        c
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="v" className="keyboard-row-key">
        v
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="b" className="keyboard-row-key">
        b
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="n" className="keyboard-row-key">
        n
      </button>
      <button onClick={(e) => onClick(e.target.dataset.key)} type="button" data-key="m" className="keyboard-row-key">
        m
      </button>
      <button
        type="button"
        data-key="Backspace"
        aria-label="backspace"
        onClick={(e) => onClick(e.target.dataset.key)}
        className="backspace keyboard-row-key"
      >
        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          viewBox="0 0 24 24"
          width="20"
          className="game-icon"
          data-testid="icon-backspace"
        >
          <path
            fill="var(--color-tone-1)"
            d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H7.07L2.4 12l4.66-7H22v14zm-11.59-2L14 13.41 17.59 17 19 15.59 15.41 12 19 8.41 17.59 7 14 10.59 10.41 7 9 8.41 12.59 12 9 15.59z"
          ></path>
        </svg>
      </button>
    </div>
  </div>
  )
}