import { Modal } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import "./WinningModal.css";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../util";
import ShareIcon from '@mui/icons-material/Share';
import toast from "../Toast";

export default function WinningModal({ isOpen, openModal, word }) {
  const { game, currentWordId } = useSelector(el => el.main)

  const onShare = () => {
    copyText()
    toast({ text: "Tus resultados han sido copiados" })
  }

  const formText = () => {
    const answers = Object.values(game).splice(0, currentWordId - 1)
    return answers.map(el => `${wordInIcons(el.colors).replace(' ', '')}\n`).join('')
  }

  const wordInIcons = colors => {
    if(!colors) return ''
    return colors.map(el => {
      if(el === 'green'){
        return'🟩'
      }
      if(el === 'grey'){
        return'⬜'
      }
      return'🟨'
    }).join('')
  }

  function copyText() {
    navigator.clipboard.writeText(
    `Adivina la palabra (${currentWordId - 1}/6 ⭐)
${formText()}
    `);
  }

  return (
    <Modal
      open={!!isOpen}
      onClose={() => {
        openModal(false);
      }}
      aria-labelledby="winning"
    >
      <div className="modal">
        <div className="modal-content">
          <ClearRoundedIcon
            onClick={() => openModal(false)}
            className="close-button"
          />
          <div className="modal-content-container rules-container">
            <h3>Exelente! 🥇</h3>
            <p>Encontraste la palabra! La palabra de hoy era <span className="bold">{capitalizeFirstLetter(word)}</span></p>
            <button onClick={onShare}><span className="mr-10">Comparte tus resultados</span> <ShareIcon /></button>
            <hr />
            <h4>Quieres mas juegos?</h4>
            <p>Juega <a href='https://playdigitsnyt.com' className="bold c-green">Digits</a> ⭐️</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
