import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const main = createSlice({
  name: "main",
  initialState: {
    game: {
      1: {word: ['', '', '', '', ''], wordFormatted: null, colors: []},
      2: {word: ['', '', '', '', ''], wordFormatted: null, colors: []},
      3: {word: ['', '', '', '', ''], wordFormatted: null, colors: []},
      4: {word: ['', '', '', '', ''], wordFormatted: null, colors: []},
      5: {word: ['', '', '', '', ''], wordFormatted: null, colors: []},
      6: {word: ['', '', '', '', ''], wordFormatted: null, colors: []},
    },
    wordOfTheDay: null,
    currentWordId: 1,
    currentWord: []
  },
  reducers: {
    setGame(state, { payload }) {
      return payload
    },
    setKey(state, { payload }) {
      if(payload === 'Backspace'){
        const arr = [...state.currentWord]
        arr.pop()
        state.currentWord = arr
      } else {
        if(state.currentWord.length === 5) return
        const updatedCurrentWord = [...state.currentWord, payload]
        state.currentWord = updatedCurrentWord
      }
    },
    setWord(state, {payload}){
      const {word} = payload
      state.game[state.currentWordId] = {
        word: word,
        wordFormatted: word.split(''),
        colors: []
      }
      state.currentWordId = state.currentWordId + 1
      state.currentWord = []
      localStorage.setItem( "gameData", JSON.stringify(state));
    },
    setColors(state, {payload}){
      state.game[payload.currentWordId].colors = payload.colors
      localStorage.setItem( "gameData", JSON.stringify(state));
    }
  },
});

const { actions, reducer } = main;

export const {
  setGame,
  setWord,
  setKey,
  setColors
} = actions;

export default reducer;
