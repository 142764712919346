export function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function countLetters(str) {
  return [...str].reduce((acc, char) => {
      acc[char] = (acc[char] || 0) + 1;
      return acc;
  }, {});
}