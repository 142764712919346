import { Modal } from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { capitalizeFirstLetter } from "../../util";
export default function LosingModal({ isOpen, openModal, word }) {
  return (
    <Modal
      open={!!isOpen}
      onClose={() => {
        openModal(false);
      }}
      aria-labelledby="winning"
    >
      <div className="modal">
        <div className="modal-content">
          <ClearRoundedIcon
            onClick={() => openModal(false)}
            className="close-button"
          />
          <div className="modal-content-container rules-container">
            <h3 className="mb-15">Mas suerte la proxima 🥲 </h3>
            <p>La palabra de hoy era <span className="bold">{capitalizeFirstLetter(word)}</span>. Puedes volver a intentarlo mañana.</p>
            <hr className="mt-20" />
            <h4>Quieres mas juegos?</h4>
            <p>Juega <a href='https://playdigitsnyt.com' className="bold c-green">Digits</a> ⭐️</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
