import "./About.scss";
import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function About() {
  return (
    <div className="game">
      <Helmet title='Acerca del juego | Adivina la palabra'/>
      <Navbar />
      <div className="game-wrapper">
      <div className="game-container about-container">
        <h1>Acerca de Adivina la palabra 🧩</h1>
        <p>Adivina la palabra es la version en espanol del popular juego Wordle, el cual puedes jugar en ingles visitando la pagina del <a href="https://www.nytimes.com/games/wordle/index.html" target='_blank' rel="noreferrer">NYT</a>.</p>
        <p>Wordle es un juego de palabras diario creado por Josh Wardle, un ingeniero de software con base en Brooklyn que ha desarrollado cierta reputacion como creador de interesantes experimentos sociales. Todos los dias, la gente de internet es recibida con un nuevo rompecabezas de palabras que solo puede ser resuelto — ¡o no! — usando una serie de pistas de proceso de eliminacion.</p>
        <Link to='/' className="button">Empieza a jugar!</Link>
        </div>
      </div>
    </div>
  );
}

export default About;
