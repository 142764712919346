import { useEffect, useRef, useState } from "react";
import Keyboard from "../Keyboard";
import { Word } from "../Word";
import "./Game.scss";
import { useDispatch, useSelector } from "react-redux";
import wordList from '../../wordList.json'
import { setKey, setWord } from "../../store/reducers/main";
import toast from "../Toast";
import WinningModal from "../WinningModal";
import dayjs from "dayjs";
import LosingModal from "../LosingModal";
require('dayjs/locale/es')
dayjs.locale('es')

export const Game = () => {
  const dispatch = useDispatch()
  const currentWordRef = useRef();
  const currentWordIdRef = useRef()
  const { currentWord, wordOfTheDay, currentWordId } = useSelector(el => el.main)
  const [isWinningModalOpen, openWinningModal] = useState(false)
  const [isLosingModalOpen, openLosingModal] = useState(false)

  useEffect(() => {
    const handleKeydown = (e) => {
      onClickKey(e.key);
    };

    document.addEventListener("keydown", handleKeydown);
    return () => document.removeEventListener("keydown", handleKeydown);
  }, []);

  useEffect(() => {
    currentWordRef.current = currentWord;
    currentWordIdRef.current = currentWordId
}, [currentWord]);

  const onClickKey = (letter) => {
    const allowedKeys = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'ñ', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'Enter', 'Backspace']
    if(!allowedKeys.includes(letter)){
      return
    }
    if(letter === 'Enter'){
      onSubmit()
    } else dispatch(setKey(letter));
  };

  const onSubmit = () => {
    const joinedCurrentWord = currentWordRef.current.join('')
    if(currentWordRef.current.length !== 5) {
      return
    }
    if(!wordList.includes(joinedCurrentWord)){
      toast({ text: "No conocemos esta palabra" })
      return
    }
    if(currentWordIdRef.current === 6 && joinedCurrentWord !== wordOfTheDay){
      setTimeout(() => {
        toast({ text: wordOfTheDay.toUpperCase() })
      }, 1500)
      setTimeout(() => {
        openLosingModal(true)
      }, 2200)
    }
    if(joinedCurrentWord === wordOfTheDay){
      setTimeout(() => {
        openWinningModal(true)
      }, 1500)
    }
    dispatch(setWord({ word: joinedCurrentWord}))
  }


  const words = [1, 2, 3, 4, 5, 6].map((el) => <Word id={el} key={el} />);

  return (
    <>
      <div className="game">{words}</div>
      <Keyboard onSubmit={onSubmit}/>
      { isWinningModalOpen && <WinningModal word={wordOfTheDay} isOpen={isWinningModalOpen} openModal={openWinningModal}/> }
      { isLosingModalOpen && <LosingModal word={wordOfTheDay} isOpen={isLosingModalOpen} openModal={openLosingModal}/> }
    </>
  );
};
