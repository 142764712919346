import classNames from "classnames";
import { useSelector } from "react-redux";
import { Game } from "../../components/Game";
import Navbar from "../../components/Navbar";

function GameContainer() {
  const {  wordOfTheDay } = useSelector((state) => state.main);

  if (!wordOfTheDay) return;

  return (
    <div className="game">
      <Navbar />
      <div className="game-wrapper">
        <div className="game-container">
          <Game />
        </div>
      </div>
    </div>
  );
}

export default GameContainer;
