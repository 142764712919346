import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useState } from "react";
import MobileMenu from "../MobileMenu";
import { capitalizeFirstLetter } from "../../util";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export default function Navbar(){
  const [isMenuOpen, openMenu] = useState(false)

  return(
    <div className="game-header">
    <MenuRoundedIcon
      className="pointer"
      style={{ fontSize: 30, marginRight: 15, marginTop: 5, width: 30 }}
      onClick={() => openMenu(!isMenuOpen)}
    />
    {isMenuOpen && <MobileMenu openModal={openMenu} />}
    <div className="game-header-container flex-column align-start">
      <div className="flex-row header-info">
        <Link to="/"><h1>Adivina la palabra 🤪</h1></Link>
        <p className="date">{capitalizeFirstLetter(dayjs(new Date()).format("dddd, MMMM DD"))}</p>
      </div>
      <p className="game-header-container-description">Wordle en español para todos aquellos que busquen un nuevo desafio. Adivina la palabra del dia!</p>
    </div>
  </div>
  )
}