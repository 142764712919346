import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import "./MobileMenu.scss";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export default function MobileMenu({ openModal }) {
  return (
    <div id="nav-menu-content">
      <div className="close-x">
        <ClearRoundedIcon
          onClick={() => openModal(false)}
          className="close-button"
        />
      </div>
      <div id="nav-menu-container">
        <div className="menu-header">
          <h2>Adivina la palabra</h2>
          <p>{dayjs(new Date()).format("dddd, MMMM DD")}</p>
        </div>
        <Link to="/como-jugar" onClick={() => openModal(false)}>
          Como jugar
        </Link>
        <Link to="/acerca-del-juego" onClick={() => openModal(false)}>
          Acerca del juego
        </Link>
        <a
          href="https://policies.google.com/technologies/partner-sites"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <div className="w-100 games-container">
          <h4 className="bold mt-20 underline mb-10">Mas juegos</h4>
          <a href="https://playdigitsnyt.com" target="_blank" rel="noreferrer">
            ⭐️ Digits
          </a>
        </div>
      </div>
    </div>
  );
}
