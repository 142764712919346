import Img1 from "../../images/reglas.png";
import Ex1 from "../../images/ex-1.png";
import Ex2 from "../../images/ex-2.png";
import Ex3 from "../../images/ex-3.png";
import Ex4 from "../../images/ex-4.png";
import Ex5 from "../../images/ex-5.png";
import "./Rules.scss";
import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Rules() {
  return (
    <div className="game">
      <Helmet title='Como jugar | Adivina la palabra'/>
      <Navbar />
      <div className="game-wrapper">
        <div className="game-container rules-container">
          <img src={Img1} alt="decorative" style={{ maxWidth: 500 }} />
          <p className="mt-20">
            El objetivo del juego es simple, adivinar la palabra oculta. La
            palabra tiene 5 letras y tienes 6 intentos para adivinarla. La
            palabra es la misma para todas las personas en ese dia.{" "}
          </p>
          <p className="mt-20">
            Cada intento debe ser una palabra valida. En cada ronda el juego
            pinta cada letra de un color indicando si esa letra se encuentra o
            no en la palabra y si se encuentra en la posicion correcta.
          </p>
          <div>
            <div className="flex-row align-center">
              <span className="rules-letter bg-green">M</span>
              <p>
                <span className="bold c-green">VERDE</span> significa que la
                letra esta en la palabra y en la posicion CORRECTA.
              </p>
            </div>
            <div className="flex-row align-center">
              <span className="rules-letter bg-yellow">A</span>
              <p>
                <span className="bold c-yellow">AMARILLO</span> significa que la
                letra letra esta presente en la palabra pero en la posicion
                INCORRECTA.
              </p>
            </div>
            <div className="flex-row align-center">
              <span className="rules-letter bg-grey">R</span>
              <p>
                <span className="bold c-grey">GRIS</span> significa que la letra
                letra NO esta presente en la palabra.
              </p>
            </div>
          </div>
          <div>
            <h3>Ejemplo</h3>
            <p>
              Imagina que la palabra a adivinar del dia sea{" "}
              <span className="bold">SOLAR</span>. Nuestro primer intento sera
              la palabra <span className="bold">CARTA</span>.
            </p>
            <img src={Ex1} className="rules-example" alt="decorative" />
            <p>
              En este caso la A y la R son letras que estan en la palabra{" "}
              <span className="bold">SOLAR</span>, pero no en esa posicion, por
              lo tanto apareceran en amarillo.
            </p>
            <p>
              Ahora intentaremos la palabra <span className="bold">POLAR</span>
            </p>
            <img src={Ex2} className="rules-example" alt="decorative" />
            <p>
              El color verde indica que las letras estan en la palabra a
              adivinar, y en la posicion correcta. Ya estamos cerca!
            </p>
            <p>
              Finalmente probaremos la palabra{" "}
              <span className="bold">SOLAR</span>
            </p>
            <img src={Ex3} className="rules-example" alt="decorative" />
            <p>Ya hemos adivinado la palabra de hoy!</p>
          </div>
          <div>
            <h3>Letras repetidas</h3>
            <p>
              La palabra del dia puede tener letras repetidas. En ese caso, las
              pistas son independientes para cada letra y tienen prioridad
              (verde es mayor a amarillo).
            </p>
            <p>
              Por ejemplo si la palabra oculta es{" "}
              <span className="bold">ROSAS</span> y utilizamos{" "}
              <span className="bold">SACOS</span> entonces se marca la primera{" "}
              <span className="bold">S</span> en amarillo y la segunda{" "}
              <span className="bold">S</span> en verde.
            </p>
            <img src={Ex4} className="rules-example" alt="decorative" />
            <p>
              Si utilizo una palabra con una sola{" "}
              <span className="bold">S</span> como{" "}
              <span className="bold">RATOS</span> entonces se marca en verde la{" "}
              <span className="bold">S</span> pues esta en la posicion correcta
              y no se avisa si hay una letra repetida.
            </p>
            <img src={Ex5} className="rules-example" alt="decorative" />
          </div>
          <div className="w-100">
            <h3>Acerca del juego</h3>
            <p>Aprende mas sobre este juego <Link to='/acerca-del-juego' className="bold">aqui</Link>!.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rules;
