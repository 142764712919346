import toastLib from 'react-hot-toast';
import './Toast.scss';

const toast = ({ title, text }) => {
  toastLib((t) => (
    <div className="flex-row toast-component">
      <p>{text}</p>
    </div>
  ));
};

export default toast;
