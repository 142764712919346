import { useEffect } from "react";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { setGame } from "./store/reducers/main";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import GameContainer from "./pages/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Rules from "./pages/Rules";
import About from "./pages/About";
dayjs.extend(utc);

const router = createBrowserRouter([
  {
    path: "/",
    element: <GameContainer />,
  },
  {
    path: "/como-jugar",
    element: <Rules />,
  },
  {
    path: "/acerca-del-juego",
    element: <About />,
  },
]);

function App() {
  const dispatch = useDispatch();

  const defaultGame = {
    1: { word: null, colors: [] },
    2: { word: null, colors: [] },
    3: { word: null, colors: [] },
    4: { word: null, colors: [] },
    5: { word: null, colors: [] },
    6: { word: null, colors: [] },
  };
  
  useEffect(() => {
    callBackendAPI()
      .then((res) => {
        const today = dayjs.utc().format("DD-MM-YYYY")
        const storedDate = localStorage.getItem('date')
        const storedGame = JSON.parse(localStorage.getItem('gameData'))

        if((storedDate === today) && localStorage.getItem('gameData')){
          return dispatch(setGame(storedGame))
        } else {
          localStorage.setItem('date', today)
          localStorage.removeItem('gameData')
          return dispatch(setGame({
            game: defaultGame,
            wordOfTheDay: res,
            currentWordId: 1,
            currentWord: []
          }))
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const callBackendAPI = async () => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/get-word`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
  }

  return await response.text();
  };

  return (
    <div className="App">
      <Toaster
        toastOptions={{
          className: "toast",
          duration: 2000,
          position: "top-center",
          style: {
            background: 'black',
            color: 'white',
          },
        }}
      />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
