import {useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import "./Word.scss";
import classNames from "classnames";
import {countLetters} from '../../util'
import {setColors} from '../../store/reducers/main'

export const Word = ({ id }) => {
  const dispatch = useDispatch()
  const { game, currentWordId, currentWord, wordOfTheDay } = useSelector(
    (el) => el.main
  );
  const word = currentWordId === id ? currentWord : game[id].wordFormatted;
  const [colors, setColorsState] = useState([])

  useEffect(() => {
    if(game[id].word){
      const data = getLetterColors(wordOfTheDay, game[id].word.split(''))
      setColorsState(data)
      dispatch(setColors({colors, currentWordId: currentWordId - 1}))
    }
  }, [game[id].word])


  function getLetterColors(targetWord, userWord) {
    const colorsArray = [];
    let targetArray = targetWord.split(''); // Convert the target word to an array

    for (let i = 0; i < userWord.length; i++) {
        const userLetter = userWord[i];

        if (targetWord[i] === userLetter) {
            colorsArray.push("green");
            
            // Remove the matched letter from targetArray
            const index = targetArray.indexOf(userLetter);
            if (index !== -1) {
                targetArray.splice(index, 1);
            }
        } else {
            colorsArray.push("placeholder");  // Just a placeholder for now
        }
    }

    // Handle the letters that are not green
    for (let i = 0; i < userWord.length; i++) {
        if (colorsArray[i] === "placeholder") {
            const userLetter = userWord[i];

            if (targetArray.includes(userLetter)) {
                colorsArray[i] = "yellow";

                // Remove the matched letter from targetArray
                const index = targetArray.indexOf(userLetter);
                if (index !== -1) {
                    targetArray.splice(index, 1);
                }
            } else {
                colorsArray[i] = "grey";
            }
        }
    }

    return colorsArray;
}


  // const getClass = (i, letter) => {
  //   if (game[id].wordFormatted) {
  //     animatedWord.push(letter)
  //     const splitWordOfTheDay = wordOfTheDay.split("");

  //     // Letter is correct
  //     if (splitWordOfTheDay[i] === letter) {
  //       colors.push('green')
  //       return `green-state-${i + 1}`;
  //     }

  //     // Words doesn't contain letter
  //     if(!splitWordOfTheDay.includes(letter)){
  //       colors.push('grey')
  //       return `grey-state-${i + 1}`;
  //     }

  //     // Word contains letter
  //     let indexOfGuessedLetter = 0
  //     splitWordOfTheDay.forEach((el, i) => {
  //       if(el === word[i]){
  //         indexOfGuessedLetter = indexOfGuessedLetter + 1
  //       }
  //     })


  //     colors.push('yellow')
  //     return `yellow-state-${i + 1}`;
  //       // const guessLettersCount = countLetters(word)
  //       // const wordOfTheDayLetterCount = countLetters(wordOfTheDay)

  //   } else {
  //     if(word?.[i]){
  //       return 'click-state'
  //     }
  //   }
  // };

  const getClass = (i, letter) => {
    if (game[id].wordFormatted) {
      return `${colors[i]}-state-${i + 1}`

    } else {
      if(word?.[i]){
        return 'click-state'
      }
    }
  };

  const letters = [1, 2, 3, 4, 5].map((el, i) => (
    <div className={classNames("letter", getClass(i, word?.[i]))} key={el}>{word?.[i]}</div>
  ));
  return <div className="word">{letters}</div>;
};
